import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { LinkEl } from './../../components/layout'

export const PrimaryButton = styled(LinkEl)`
  color: white;
  background: ${themeGet('colors.colorPrimary')};
  color: white;
  border: 1px solid ${themeGet('colors.colorPrimary')};
  border-radius: 12px;
`

export const SecondaryButton = styled(LinkEl)`
  color: var(--Actions-Primary, #ff7d0e);
  border-radius: 12px;
  border: 1px solid var(--Actions-Primary, #ff7d0e);
  background: #fff;
`

export const PageContainer = styled.div`
  max-width: 1120px;
  margin: 140px auto 30px;
  justify-content: space-around;
  list-style-type: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${themeGet('breakpoints.1')}px) {
    margin-top: 48px;
  }
`

export const PageTitle = styled.h1`
  color: #263238;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 12px;
  @media screen and (max-width: ${themeGet('breakpoints.1')}px) {
    font-size: 20px;
  }
`

export const PageText = styled.p`
  color: #263238;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 32px;
  margin-top: 0;
  line-height: 140%;
  @media screen and (max-width: ${themeGet('breakpoints.1')}px) {
    font-size: 16px;
    margin: 0 0 32px;
  }
`

export const ButtonGroups = styled.div`
  display: flex;
  gap: 24px;
  @media screen and (max-width: ${themeGet('breakpoints.1')}px) {
    gap: 15px;
    justify-content: center;
    margin-bottom: 20px;
  }
  a {
    border-radius: 12px;
    padding: 10px 16px;
    display: block;
    max-height: 50px;
    line-height: 25px;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
  }
`

export const ErrorImage = styled.div`
  margin-bottom: 36px;
  display: block;
  @media screen and (max-width: ${themeGet('breakpoints.1')}px) {
    display: none;
  }
`

export const LeftContainer = styled.div`
  width: 45%;
  @media screen and (max-width: ${themeGet('breakpoints.1')}px) {
    width: 100%;
    padding: 0 24px;
    text-align: left;
    /* padding-top: 30px; */
  }
`

export const Image = styled.img`
  max-width: 100%;
`

export const FlexStyled = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: ${themeGet('breakpoints.1')}px) {
    flex-direction: column;
  }
`

export const MobileImage = styled.div`
  display: none;
  @media screen and (max-width: ${themeGet('breakpoints.1')}px) {
    display: block;
    margin-bottom: 42px;
    text-align: center;
  }
`

export const DesktopImage = styled.div`
  display: block;
  @media screen and (max-width: ${themeGet('breakpoints.1')}px) {
    display: none;
  }
`
