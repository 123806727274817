import SessionManager from './SessionManager'
import util from '../util'
import Cookies from 'js-cookie'
import debug from 'debug'

const log = debug('carla:api')

/**
 * wait for SessionManager to fetch device id from backend or requests will fail
 * @returns {Promise<boolean>}
 */
export const waitAuthKey = async () => {
  while (!SessionManager.getAuthKey()) {
    log('waiting for device id')
    await util.sleep(250)
  }
  return true
}

const getOrigin = () => {
  if (util.isLocal() && util.isBrowser()) {
    return window.location.origin
  }
  if (!import.meta.env.VITE_API_ENDPOINT) throw new Error('API origin not found')
  return import.meta.env.VITE_API_ENDPOINT
}

const getEventsOrigin = () => {
  if (!import.meta.env.VITE_EVENTS_ORIGIN_ENDPOINT) throw new Error('Event origin not found')
  return import.meta.env.VITE_EVENTS_ORIGIN_ENDPOINT
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const send = async (url: string, options: { [key: string]: any } = {}) => {
  const data = options.data || null
  const method = options.method || 'POST'
  const json = options.json !== false
  const headers = options.headers || null
  const isSecure = options.isSecure || true
  const mode = options.mode || 'cors'
  const language = options.language || 'en'

  log(`sending request to "${url}", method: "${method}", data:`, data)

  const req: RequestInit = {
    headers: {},
    method: method,
    mode: mode
  }

  if (json) req.headers['content-type'] = 'application/json'
  if (data) req.body = JSON.stringify(data)
  if (headers) req.headers = headers

  req.headers['Access-Control-Allow-Origin'] = '*'
  req.headers['Access-Control-Allow-Methods'] = 'GET,POST,PUT,PATCH'
  req.headers['Access-Control-Allow-Credentials'] = true
  req.headers['Access-Control-Allow-Headers'] = 'Authorization,deviceId,language,userCountry'
  log(`isSecure ${isSecure}`)
  if (isSecure) {
    req.headers['Authorization'] = SessionManager.getAuthKey()
    req.headers['deviceId'] = SessionManager.getCarlinDeviceId()
    req.headers['language'] = language
    req.headers['userCountry'] = SessionManager.getUserCountry()
  }

  const res = await fetch(url, req)


  if (res.status >= 400) {
    if (res.status === 401) {
      // TODO: review API
      SessionManager.setAuthKey(null)
      await SessionManager.fetchAuthKey()
    }
    throw new APIError(`${res.status}: ${res.statusText}`, res.status)
  }
  // no content, example: "no cars"
  if (res.status === 204) {
    return null
  }

  const contentType = res.headers.get('content-type')
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return await res.json()
  } else {
    return await res.text()
  }
}

/**
 * extension of Error class with status and message received from API
 */
export class APIError extends Error {
  public status: number // To suppress type error on status field
  constructor(message, status) {
    super()
    this.message = message
    this.status = status
  }
}

const API = {
  async nearbyCarSearch(latitude, longitude, pickupDateTime, dropOffDateTime, age, promoCode) {
    return await send(`${getOrigin()}/s/nearbyCarSearch/`, {
      data: {
        latitude,
        longitude,
        pickupDateTime,
        dropOffDateTime,
        age,
        promoCode
      }
    })
  },

  async searchNearbyBrokerLocations(lat, lon, radius) {
    return await send(`${getOrigin()}/s/searchNearbyBrokerLocations/`, {
      data: {
        latitude: lat,
        longitude: lon,
        radius: radius
      }
    })
  },

  async registerToBackend(language) {
    return await send(`${getOrigin()}/registerDevice`, {
      data: {
        country: SessionManager.getUserCountry(),
        language: language,
        oldDeviceId: SessionManager.getDeviceId(),
        os: 'web'
      },
      isSecure: false
    })
  },

  async updateDevice(deviceToken, language) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/updateDevice`, {
      method: 'PATCH',
      data: {
        deviceToken: deviceToken,
        language: language
      }
    })
  },

  async searchPlaces(queryString) {
    if (!queryString) return []
    await waitAuthKey()
    return await send(`${getOrigin()}/s/searchPlaces/${queryString}`, {
      method: 'GET'
    })
  },

  async getPromoCode(queryString) {
    await waitAuthKey()
    const authKey = SessionManager.getAuthKey()
    const deviceKey = SessionManager.getCarlinDeviceId()

    return await send(`${getOrigin()}/s/getPromoDetails/?promoCode=${queryString}`, {
      method: 'GET',
      headers: {
        deviceId: deviceKey,
        Authorization: authKey
      }
    })
  },

  async searchCars(csArgs, carType) {
    await waitAuthKey()

    const carTypeSearchParam = carType ? `?carType=${carType}` : ''

    return await send(`${getOrigin()}/s/searchCars/${carTypeSearchParam}`, {
      data: csArgs
    })
  },

  async getNoCarsOptions(csArgs) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/getNoCarsRecommendation/`, {
      data: csArgs
    })
  },

  async getAncillaryProducts(carRentalProduct, language) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/ancillaryProduct/`, {
      data: carRentalProduct,
      language
    })
  },

  async makeModifications(carRentalProduct) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/makePrecheckoutModifications/`, {
      data: carRentalProduct
    })
  },

  async completeReservation(xo2Details) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/completeReservation`, {
      data: xo2Details
    })
  },

  async getClosestLocation() {
    return await send(`${getOrigin()}/getClosestLocation`, {
      method: 'GET',
      isSecure: false
    })
  },

  async searchWithId(searchId) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/searchWithId/?searchId=${searchId}`, {
      method: 'GET'
    })
  },

  async getDeeplinkDetails(productId) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/getDeeplinkDetails/${productId}`, {
      method: 'GET'
    })
  },

  async addDetails(xo1Details, language) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/providedDetails/`, {
      data: xo1Details,
      language
    })
  },

  async sendFeedbackForReservation(id, reservationId, score) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/feedback/${id}/${reservationId}?score=${score}`, {
      method: 'GET',
      json: false
    })
  },

  async sendEvent(eventParams) {
    if (import.meta.env.DEV) return // do not send event in development
    await waitAuthKey()
    return await send(getEventsOrigin(), {
      data: eventParams,
      mode: 'no-cors'
    })
  },

  async getPlace(areaId) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/getPlace/${areaId}`, {
      method: 'GET'
    })
  },

  async searchAvailableAreas(pickupDateTime, dropOffDateTime, pickupLocation, dropOffLocation, age) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/searchAvailableAreas/`, {
      data: { pickupDateTime, dropOffDateTime, pickupLocation, dropOffLocation, age }
    })
  },

  async requestCouponCode(body) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/grantCouponCode`, {
      method: 'POST',
      data: body
    })
  },

  async retrieveReservation(args, language) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/retrieveSingleReservation`, {
      method: 'POST',
      data: args,
      language
    })
  },

  async askForSupport(args) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/contactUs`, {
      method: 'POST',
      data: args
    })
  },

  async getFAQs(key, language) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/selfHelpChildItems/${key}`, {
      method: 'GET',
      language
    })
  },

  async getPopularLocations() {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/getRecommendedDestinations`, {
      method: 'GET'
    })
  },

  async getLandingInfo(locationID) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/getLocalizedLandingInfo/${locationID}`, {
      method: 'GET'
    })
  },

  async getDynamicPopularLocations(id) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/getRecommendedLocationsForArea/${id}`, {
      method: 'GET'
    })
  },

  async getThreeDSInfo(ThreeDSWebRequest) {
    await waitAuthKey()
    return await send(`${getOrigin()}/t/threeDSWeb/`, {
      method: 'POST',
      data: ThreeDSWebRequest
    })
  },

  async check3DsStatus(token, productID) {
    await waitAuthKey()
    return await send(`${getOrigin()}/t/process3Ds/${token}&${productID}`, {
      method: 'GET'
    })
  },

  // returns productID of a spesific car -> for 3Ds
  async getSelectedCar(carData) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/selectedCar/`, {
      method: 'POST',
      data: carData
    })
  },

  async saveUserCardInfo(WebUserCardInfo) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/saveUserCardInfo/`, {
      method: 'POST',
      data: WebUserCardInfo
    })
  },

  async getCarlaPlusDetailInfo(email, channel, language) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/getCarlaPlusDetails/?email=${email}`, {
      method: 'GET',
      headers: { channel },
      language
    })
  },

  async searchHotel(params) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/searchHotelsV2`, {
      method: 'POST',
      data: params
    })
  },

  async getHotelFilters() {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/hotelFilters`, {
      method: 'GET'
    })
  },

  async getCarFilters(bundleId) {
    if (!bundleId) return []
    await waitAuthKey()
    return await send(`${getOrigin()}/s/carFilters/?bundleId=${bundleId}`, {
      method: 'GET'
    })
  },

  async getHotelLocations(queryString) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/searchHotelPlaces/?searchString=${queryString}`, {
      method: 'GET'
    })
  },

  async getHotelDetail(params) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/getDetailsForHotelV2`, {
      method: 'POST',
      data: params
    })
  },

  async providedHotelDetails(params) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/providedHotelDetails`, {
      method: 'POST',
      data: params
    })
  },

  async completeHotelBooking(xo2Details) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/completeHotelBooking`, {
      data: xo2Details
    })
  },

  async searchBundle(params) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/searchBundle`, {
      method: 'POST',
      data: params
    })
  },

  async getBundleFilters() {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/filterProducts`, {
      method: 'GET'
    })
  },

  async providedBundleDetails(params) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/providedBundleDetails`, {
      method: 'POST',
      data: params
    })
  },

  async completeHotelBookingV2(xo2Details) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/completeHotelBookingV2`, {
      data: xo2Details
    })
  },

  async getPrizeByEmail(email) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/applyPrize?email=${email}`, {
      method: 'GET'
    })
  },

  async getPrizeDetails() {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/prizeDetails/`, {
      method: 'GET'
    })
  },

  async getReservationDetailForRedirectedXO1(carId) {
    await waitAuthKey()

    return await send(`${getOrigin()}/s/fetchCarRentalProduct/${carId}`, {
      method: 'GET'
    })
  },

  async startKlarnaPayment(XO2Details) {
    await waitAuthKey()

    return await send(`${getOrigin()}/s/startKlarnaPayment`, {
      method: 'POST',
      data: XO2Details
    })
  },

  async sendPreConversionEvent(stage) {
    await waitAuthKey()
    const utmSource = Cookies.get('utm_source')
    const redirectId = Cookies.get('redirect_id')
    const carId = Cookies.get('car_id')

    if (utmSource !== 'skyscanner' || !redirectId) return
    return await send(`${getOrigin()}/s/tracker/pre-conversion`, {
      method: 'POST',
      data: {
        redirectId,
        productId: carId,
        stageName: stage
      }
    })
  },

  async sendConversionEvent(reservationId) {
    await waitAuthKey()
    const utmSource = Cookies.get('utm_source')
    const redirectId = Cookies.get('redirect_id')

    if (utmSource !== 'skyscanner' || !redirectId || !reservationId) return
    return await send(`${getOrigin()}/s/tracker/conversion`, {
      method: 'POST',
      data: {
        redirectId,
        reservationId
      }
    })
  },

  async getReviews(language) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/review`, {
      method: 'GET',
      language
    })
  },

  async searchDowntownSuppliers(pickupDateTime, dropOffDateTime, pickupLocation, dropOffLocation, age, promoCode) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/searchDowntownCars`, {
      method: 'POST',
      data: { pickupDateTime, dropOffDateTime, pickupLocation, dropOffLocation, age, promoCode }
    })
  },

  async getCallCenterDetails() {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/callCenterDetails`, {
      method: 'GET'
    })
  },

  async getCancellationTerms(reservationId, email, language) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/cancellationTerms/`, {
      method: 'POST',
      data: { email, reservationId },
      language
    })
  },

  async confirmCancellation(reservationId, email, useCredit, language) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/confirmCancellation/`, {
      method: 'POST',
      data: { email, reservationId, useCredit },
      language
    })
  },

  async startSurvey(language) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/startSurvey/survey.id.success`, {
      method: 'GET',
      language
    })
  },

  async startSurveySubmit(data, language) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/answerQuestion`, {
      method: 'POST',
      data,
      language
    })
  },

  async getVoucherPDF(reservationId) {
    if (!reservationId) return {}
    await waitAuthKey()
    return await send(`${getOrigin()}/s/getVoucherPDF/${reservationId}`, {
      method: 'GET'
    })
  },

  async sendVoucherToGivenEmail(reservationId, email) {
    if (!reservationId || !email) return { error: 'Can not send email!' }
    await waitAuthKey()
    return await send(`${getOrigin()}/s/sendVoucherToGivenEmail/`, {
      method: 'POST',
      data: { reservationId, email }
    })
  },

  async modifyReservation(reservationId, type, carSearchArgs) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/searchCarsForModification/`, {
      method: 'POST',
      data: { reservationId, type, carSearchArgs }
    })
  },

  async completeModifyReservation(oldReservationId, details, payNowDifference, modificationType, paymentOption) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/modifyReservation/`, {
      method: 'POST',
      data: { oldReservationId, details, payNowDifference, modificationType, paymentOption }
    })
  },

  async upsellAncillaryProducts(reservationId, ancillaryProductId) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/upsellAncillaryProducts`, {
      method: 'POST',
      data: { ancillaryUpsellPurchaseList: [{ reservationId, ancillaryProductId }], isSuccessUpsell: true }
    })
  },

  async upsellQuote(reservationId) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/getAllUpsellQuotes`, {
      method: 'POST',
      data: { reservationIdList: [reservationId] }
    })
  },

  async emailVerificationCode(email) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/emailVerificationCode?email=${email}`, {
      method: 'POST'
    })
  },

  async verifyEmail(email, verificationCode) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/verifyEmail`, {
      method: 'POST',
      data: { email, verificationCode }
    })
  },

  async getCancellableAncillaries(reservationId, language) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/cancellableAncillaryProducts?reservationId=${reservationId}`, {
      method: 'GET',
      language
    })
  },

  async getAncillaryCancellationTerms(reservationId, type, language) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/ancillaryCancellationTerms/`, {
      method: 'POST',
      data: { reservationId, type },
      language
    })
  },

  async confirmCancelAncillary(reservationId, ancillaryProductId, withCarlaCash, language) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/confirmAncillaryCancellation/`, {
      method: 'POST',
      data: { reservationId, ancillaryProductId, withCarlaCash },
      language
    })
  },
  
  async validateEmail(email: string) {
    await waitAuthKey()
    return await send(`${getOrigin()}/s/validateEmailAddress/${email}`, {
      method: 'GET'
    })
  }
}

export default API
