import sanityClient from './config'
import * as _ from 'lodash-es'
import { escapeHtml, formatBlogDate } from '../util/blog'
import debug from 'debug'
import { DEFAULT_LANG } from './constants'
import { saveToCache } from './cache'

const log = debug('carla:sanity')

export const getAllArticles = `
  *[_type == "article"] | order(publishDate desc) {
    _id,
    language,
    _createdAt,
    slug {
      current
    },
    title,
    content,
    og {
      description,
      title,
      keywords,
    },
    img,
    imageWidth,
    imageHeight,
    publishDate,
    authors->{
      name,
      twitter,
      photo,
      dueDate,
      description,
      linkedin,
      instagram
    }
  }
`

export const getAllArticleBySlug = (slug) => `
  *[_type == "article" && slug.current=="${slug}"] {
    _id,
    language,
    _createdAt,
    slug {
      current
    },
    title,
    content,
    og {
      description,
      title,
      keywords,
    },
    img,
    imageWidth,
    imageHeight,
    publishDate,
    authors->{
      name,
      twitter,
      photo,
      dueDate,
      description,
      linkedin,
      instagram
    }
  }
`

export const getAllFAQByLang = (lang = 'en') => `
  *[_type == "faq" && language == "${lang}"] | order(order asc) {
    _id,
    description,
    title,
    order,
    imageUrl,
    questions[]-> {
      _id,
      title,
      description
    }
  }
`

export function parseArticles(articles) {
  let results = {}
  if (articles && articles.length) {
    articles.forEach((raw) => {
      const rawSlug = raw.slug?.current || ''
      if (_.isEmpty(rawSlug)) return

      const lang = raw.language || DEFAULT_LANG
      if (!results[rawSlug]) results[rawSlug] = {}
      results[rawSlug][lang] = {
        ...raw,
        slug: rawSlug,
        ampLink: `/amp/blog/${rawSlug}.html`,
        canonicalLink: `/blog/${rawSlug}.html`,
        ampUrl: `https://rentcarla.com/amp/blog/${rawSlug}.html`,
        canonicalUrl: `https://rentcarla.com/blog/${rawSlug}.html`,
        imageUrl: raw.img,
        escapedTitle: escapeHtml(raw.title),
        datePublishedFormatted: formatBlogDate(new Date(raw?.publishDate)),
        datePublished: raw?.publishDate,
        description: raw.og?.description,
        isSanityContent: true
      }
    })
  }
  return results
}

export async function fetchArticles() {
  log('Fetch sanity articles...')
  const articles = await sanityClient.fetch(getAllArticles)

  let results = parseArticles(articles)
  saveToCache(results)
  return results
}

export async function fetchArticleBySlug(slug) {
  log('Fetch sanity article...', slug)
  const article = await sanityClient.fetch(getAllArticleBySlug(slug))

  let results = {}
  if (article && article.length) {
    article.forEach((raw) => {
      const rawSlug = raw.slug?.current || ''
      if (_.isEmpty(rawSlug)) return

      const lang = raw.language || DEFAULT_LANG
      if (!results[lang]) results[lang] = {}
      results[lang] = {
        ...raw,
        language: lang,
        slug: rawSlug,
        ampLink: `/amp/blog/${rawSlug}.html`,
        canonicalLink: `/blog/${rawSlug}.html`,
        ampUrl: `https://rentcarla.com/amp/blog/${rawSlug}.html`,
        canonicalUrl: `https://rentcarla.com/blog/${rawSlug}.html`,
        imageUrl: raw.img,
        escapedTitle: escapeHtml(raw.title),
        datePublishedFormatted: formatBlogDate(new Date(raw?.publishDate)),
        datePublished: raw.publishDate,
        description: raw.og?.description,
        isSanityContent: true
      }
    })
  }
  return results
}

export async function fetchFAQ(lang) {
  log('Fetch FAQ...')
  const faqData = await sanityClient.fetch(getAllFAQByLang(lang))
  return faqData
}
