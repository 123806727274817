import date from './date'
import money from './money'
import blog from './blog'
import dev from './dev'
import location from './location'
import price from './price'

// running environment
const localHosts = ['localhost', '127.0.0.1', 'local.rentcarla.com']

/**
 *
 * @returns boolean
 */
export const isClient = typeof window !== 'undefined' && window.document

/**
 *
 * @returns boolean
 */
export const isServer = !isClient

const util = {
  ...date,
  ...money,
  ...blog,
  ...location,
  ...price,
  dev,
  /**
   * postpone execution of {fn} {delay} milliseconds
   * @param fn {Function}
   * @param delay {number}
   * @returns {Function}
   */
  debounce(fn, delay) {
    let lastCalled
    return (...args) => {
      clearTimeout(lastCalled)
      lastCalled = setTimeout(fn.bind(this, ...args), delay)
    }
  },

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  },

  // check if current execution environment is web browser or node server
  // dedicated
  isBrowser() {
    return typeof window !== 'undefined' && window.document
  },
  isServer() {
    return !this.isBrowser()
  },

  /**
   * webpack sets NODE_ENV to production even in development mode, so we check from host in client
   * note: local environment is also development environment
   */
  isDevelopment() {
    return import.meta.env.MODE === 'development'
  },
  isProduction() {
    return import.meta.env.MODE === 'production'
  },
  isLocal() {
    if (this.isServer()) return !process.env.CLOUD // this is set in .ebextensions
    if (this.isBrowser()) {
      const { hostname } = window.location
      return localHosts.includes(hostname) || hostname.startsWith('192.168') // dev in same local network
    }
  },

  /**
   * true if current window belongs to google translate
   * @returns {boolean}
   */
  isGoogleTranslate() {
    if (!this.isBrowser()) return false
    const host = window.location.hostname
    return host === 'translate.googleusercontent.com' || host === 'translate.google.com'
  },

  /**
   * get operating system from browser
   * @returns {string|null} ios|android|osx|windows|linux
   */
  getOS() {
    if (!this.isBrowser() || window.MSStream) return null

    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) return 'ios'
    if (/Android/.test(navigator.userAgent)) return 'android'
    if (/Macintosh/.test(navigator.userAgent)) return 'osx'
    if (/Win/.test(navigator.userAgent)) return 'windows'
    if (/Linux/.test(navigator.userAgent)) return 'linux'

    return null
  },

  getUPCode() {
    if (this.isDevelopment()) return 'UP-58605314-99'
    else return 'UP-58605314-1'
  },

  isNum(x) {
    return !isNaN(parseInt(x))
  }, // true if not a number

  // takes react props and merge url + query params of a router component
  getUrlParams(props) {
    const searchParams = new URLSearchParams(props.location?.search)
    const queryParams = {}

    for (let [key, value] of searchParams) {
      queryParams[key] = value
    }

    return { ...queryParams, ...props.match?.params }
  },

  isCookiesEnabled() {
    if (typeof document === 'undefined') return false
    // Quick test if browser has cookieEnabled host property
    if (navigator.cookieEnabled) return true
    // Create cookie
    document.cookie = 'cookietest=1'
    const cookieEnabled = document.cookie.indexOf('cookietest=') !== -1
    // Delete cookie
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT'
    return cookieEnabled
  },

  getFromLocalStorage(key) {
    if (!this.isCookiesEnabled()) return false // disabled
    if (typeof localStorage === 'undefined') return false // SSR
    const str = localStorage.getItem(key)
    if (str) {
      try {
        return JSON.parse(str)
      } catch (e) {
        return false
      }
    }
    return false
  },

  saveToLocalStorage(key, object) {
    if (!this.isCookiesEnabled()) return false // disabled
    if (typeof localStorage === 'undefined') return false // SSR
    try {
      localStorage.setItem(key, JSON.stringify(object))
    } catch (err) {}
  },

  saveToLocalStorageWithExpiry(key, value, ttl) {
    if (!this.isCookiesEnabled()) return false // disabled
    if (typeof localStorage === 'undefined') return false // SSR
    const now = new Date()
    const item = {
      value,
      expiry: now.getTime() + ttl
    }
    localStorage.setItem(key, JSON.stringify(item))
  },

  getFromLocalStorageWithExpiry(key) {
    if (!this.isCookiesEnabled()) return false // disabled
    if (typeof localStorage === 'undefined') return false // SSR
    const str = localStorage.getItem(key)
    if (!str) return false
    const item = JSON.parse(str)
    const now = new Date()
    if (item.expiry && now.getTime() >= item.expiry) {
      localStorage.removeItem(key)
      return false
    }
    return item.value
  },

  removeFromLocalStorage(key) {
    if (!this.isCookiesEnabled()) return false // disabled
    if (typeof localStorage === 'undefined') return false // SSR
    localStorage.removeItem(key)
  },

  getFromSessionStorage(key) {
    if (!this.isCookiesEnabled()) return false // disabled
    if (typeof sessionStorage === 'undefined') return false // SSR
    sessionStorage.getItem(key)
  },

  saveToSessionStorage(key, value) {
    if (!this.isCookiesEnabled()) return false // disabled
    if (typeof sessionStorage === 'undefined') return false // SSR
    sessionStorage.setItem(key, value)
  },

  removeFromSessionStorage(key) {
    if (!this.isCookiesEnabled()) return false // disabled
    if (typeof localStorage === 'undefined') return false // SSR
    localStorage.removeItem(key)
  },

  /**
   * to use in browser
   * @param key {string}
   * @param func {function}
   */
  setDevFunction(key, func) {
    if (this.isBrowser() && window.dev) {
      window.dev[key] = func
    }
  },
  removeDevFunction(key) {
    if (this.isBrowser() && window.dev) {
      delete window.dev[key]
    }
  },

  // get any url replace http with https
  toHttps(url) {
    return url ? url.replace(/^http:\/\//i, 'https://') : ''
  },

  // get google analytics id according to environment
  getGATrackingId() {
    if (util.isProduction()) return 'G-R3X2RTDTQT'
    if (util.isDevelopment()) return 'G-4W9Q4ZECRP'
    console.error('no environment for google analytics')
    return ''
  },
  // DEV: G-4W9Q4ZECRP
  // PROD: G-R3X2RTDTQT
  // PROD GA4: 191216130
  // LOCAL : G-6YV7B5GTCW

  escapeHtml(unsafe) {
    return unsafe.split('"').join('&quot;')
  },

  validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) return true
    return false
  },

  getYandexContent() {
    // TODO: review
    return import.meta.env.VITE_CARLA_ENV === 'production' ? 'ca57553568c05467' : '46ffb6345f541e86'
  },

  getMockDataUpliftLoadObj() {
    const mockData = {
      travelers: [
        {
          id: 0,
          first_name: 'John',
          last_name: 'Doe',
          date_of_birth: '04/07/1963'
        }
      ],
      air_reservations: [
        {
          airline_name: 'AI',
          origin: 'MIA',
          destination: 'JFK',
          trip_type: 'oneway',
          itinerary: [
            {
              departure_apc: 'MIA',
              departure_time: '20241223',
              arrival_apc: 'JFK',
              arrival_time: '20241225',
              fare_class: 'Economy',
              carrier_code: 'NF'
            }
          ],
          insurance: [
            {
              id: '0',
              types: ['cancellation'],
              price: 0
            }
          ]
        }
      ],
      add_ons: [
        {
          id: '0',
          name: 'Priority Boarding',
          price: 0
        }
      ],
      order_amount: 0
    }
    return mockData
  },

  getUrl() {
    if (this.isLocal() && this.isBrowser()) {
      return 'localhost:3000'
    }
    if (this.isProduction()) return 'https://rentcarla.com'
    else if (this.isDevelopment()) return 'https://dev.rentcarla.com'
    return ''
  },

  getRandomReviewIndex(reviews) {
    return Math.floor(Math.random() * reviews.length)
  },

  getBounds(locations) {
    const getNE = (a, b) => [Math.max(a[0], b[0]), Math.max(a[1], b[1])]
    const getSW = (a, b) => [Math.min(a[0], b[0]), Math.min(a[1], b[1])]
    const boundNE = locations.reduce(getNE, [-90, -180])
    const boundSW = locations.reduce(getSW, [90, 180])
    return { east: boundNE[1], west: boundSW[1], north: boundNE[0], south: boundSW[0] }
  },

  getTransmission(acrissCode) {
    return 'MNC'.includes(acrissCode[2]) ? 'M' : 'A'
  },

  // TODO: remove after backend integrates enum
  isFlexibleCancellation(policy) {
    const policyStrings = ['flex', 'fles', 'esnek']
    return policyStrings.some((str) => policy.toLowerCase().includes(str))
  }
}

export default util
