import { createSlice } from '@reduxjs/toolkit'
import { CarlaPlusState } from '@components/CarlaPlus/types'

const initialState: CarlaPlusState = {
  isSelected: false,
  hasPurchased: false,
  price: { price: 0, currency: 'USD', iso4217Currency: 840 },
  discount: null,
  type: null
}

export const carlaPlusSlice = createSlice({
  name: 'carlaPlusSlice',
  initialState,
  reducers: {
    setIsSelected: (state, action) => {
      state.isSelected = action.payload
    },
    setHasPurchased: (state, action) => {
      state.hasPurchased = action.payload
    },
    setPrice: (state, action) => {
      state.price = action.payload
    },
    setCarlaPlusDiscount: (state, action) => {
      state.discount = action.payload
    },
    setCarlaPlusType: (state, action) => {
      state.type = action.payload
    }
  }
})

export const { setIsSelected, setHasPurchased, setPrice, setCarlaPlusDiscount, setCarlaPlusType } =
  carlaPlusSlice.actions

export default carlaPlusSlice.reducer
