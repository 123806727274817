import { useQuery } from 'react-query'
import { fetchArticleBySlug } from './../sanity'
import { DEFAULT_LANG } from '@base/constants'
import blogUtil from '@util/blog'

const articleMap = blogUtil.getArticleMap()

const getArticleBySlugFunc = async (slug) => {
  const legacyArticle = articleMap?.[slug]

  if (legacyArticle) {
    legacyArticle.isLegacy = true // a flag to know it legacy blog
    return Promise.resolve({
      [DEFAULT_LANG]: legacyArticle
    })
  }
  const results = await fetchArticleBySlug(slug)
  return results
}

export function useArticlesBySlug(slug) {
  const { data = {}, isLoading, error } = useQuery(['sanity-article', slug], () => getArticleBySlugFunc(slug))
  return {
    data,
    isLoading,
    error
  }
}
