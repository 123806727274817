import { useAppSelector } from './useReduxHooks'
import type { SupportedLangs } from '@base/constants'
import { DEFAULT_LANG } from '@base/constants'

function useCurrentLang(): SupportedLangs {
  const currentLang = useAppSelector((state) => state.user.language)

  return (currentLang || DEFAULT_LANG) as SupportedLangs
}

export default useCurrentLang
