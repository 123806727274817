// TODO: Add a list of queries and generalize, not just for sardine
import { useQuery } from 'react-query'
import injectSardineScript from './3nd-party/sardine'
import SessionManager from './services/SessionManager'

async function getSardineSessionKey() {
  const response = await fetch('/carlin/sardineSessionKey', { headers: { 'X-Requested-With': 'Fetch' } })
  if (!response.ok) {
    throw new Error('Failed to fetch Sardine session key');
  }
  return await response.json()
}

export default function GlobalQueryHandler() {
  useQuery('sardine', getSardineSessionKey, {
    retry: Infinity,
    retryDelay: 10000,
    onSuccess: (data) => {
      injectSardineScript(
        import.meta.env.MODE === 'development',
        import.meta.env.VITE_SARDINE_CLIENT_ID,
        data.sessionKey,
        SessionManager.getCarlinDeviceId()
      )
    },
  })
  return null
}
