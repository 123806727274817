import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { useGrowthBookFlag } from './growthbook'

export default function MuiDynamicThemeProvider({ children }) {
  const feature = useGrowthBookFlag('skyscanner-typography-color', {
    fontFamily: 'Quicksand, Quicksand Regular, sans-serif',
    primaryMain: '#FF7D0E',
    primaryDark: '#FF7D0E',
    primaryLight: '#FFF9F5',
    gradient: 'linear-gradient(180deg, #FF963D 0%, #FF7D0E 100%)',
    btnCustom: '#0076FF',
    darkCustom: '#ED8508',
    successCustom: '#3EAA77'
  })

  const theme = createTheme({
    palette: {
      primary: {
        main: feature.primaryMain,
        dark: feature.primaryDark,
        light: feature.primaryLight
      },
      success: {
        main: feature.successCustom
      },
      text: {
        primary: '#525266',
        secondary: '#696984'
      },
      background: {
        default: '#EFF1F8'
      }
    },
    typography: {
      fontFamily: feature.fontFamily
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 1024,
        lg: 1280,
        xl: 1536
      }
    },
    components: {
      MuiUseMediaQuery: {
        defaultProps: {
          noSsr: true
        }
      },
      MuiCssBaseline: {
        styleOverrides: {
          ':root': {
            '--mui-palette-primary-main': feature.primaryMain,
            '--mui-palette-primary-light': feature.primaryLight,
            '--mui-palette-primary-dark': feature.primaryDark,
            '--mui-custom-gradient': feature.gradient,
            '--mui-custom-btn': feature.btnCustom,
            '--mui-custom-dark': feature.darkCustom,
            '--mui-custom-success': feature.successCustom
          },
          '*, body, button, input, textarea, select': {
            fontFamily: feature.fontFamily
          }
        }
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
