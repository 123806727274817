import { useIsomorphicLayoutEffect } from 'usehooks-ts'
import { useLocation } from 'react-router-dom'

function ScrollToTop() {
  const location = useLocation()
  useIsomorphicLayoutEffect(() => {
    if (typeof window !== 'undefined') window.scrollTo(0, 0)
  }, [location])
  return null
}

export default ScrollToTop
