import { useFeatureValue, useFeatureIsOn } from '@growthbook/growthbook-react'

export function useGrowthBookFlag(flagName: string, defaultValue) {
  if (!flagName) throw new Error('Flag name must be declare!')
  const flag = useFeatureValue(flagName, defaultValue)
  return flag ? flag : defaultValue
}

export function useGBFeatureIsOn<T>(flagName: string, defaultValue: T) {
  if (!flagName) throw new Error('Flag name must be declare!')
  const flag = useFeatureIsOn(flagName)
  return flag ? flag : defaultValue
}
